<template>
<div class="justify-content-center">
  <v-row>
    <v-col md="6" lg="12">
      <span class="font-weight-semibold mt-3 mb-3">Détails de la caisse <strong>{{fund_amount}}</strong> -- Montant global: <strong>{{fund_label}}</strong> FCFA</span>
    </v-col>
  </v-row>
  <v-row>
    <v-col md="6" lg="12">
      <span class="font-weight-semibold mt-3 mb-3">Afficher le la liste des transactions à partir d'un interval de période</span>
    </v-col>
  </v-row>
  <v-row class="my-3">
    <v-col md="6"
           lg="4">
      <v-text-field
          v-model="dateFrom"
          dense
          type="date"
          hide-details
          label="Date de debut"
          outlined
          placeholder="Commencer à partir du"
      ></v-text-field>
    </v-col>
    <v-col md="6"
           lg="4">
      <v-text-field
          v-model="dateTo"
          dense
          type="date"
          hide-details
          label="Date de fin"
          outlined
          placeholder="Terminer au"
      ></v-text-field>
    </v-col>
    <v-col md="6"
           lg="4">
      <v-btn
          color="primary"
          @click="getFundPaymentInterval()"
      >
        Afficher
      </v-btn>
    </v-col>
  </v-row>
  <v-data-table
      :headers="headers"
      :items="paymentList"
      item-key="id"
      class="table-rounded"
      :search="search"
  >
    <template #top>
      <v-toolbar
          flat
      >
        <v-toolbar-title>Liste des Transaction</v-toolbar-title>
      </v-toolbar>
      <v-spacer></v-spacer>
      <v-row style="margin-left: 1.5rem; margin-right: 1.5rem;">
        <v-col
            cols="12"
            sm="4"
        >
          <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="exportToExcel"
              v-if="paymentList.length > 0"
          >
           Exporter au Format Excel
          </v-btn>
        </v-col>
        <v-col
            cols="12"
            sm="4"
        >
          <v-btn
              color="primary"
              dark
              class="mb-2"
          >
           Mode compta
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-data-table>
  <v-row>
    <v-col
        cols="12"
        md="12"
    >
      <span class="font-weight-semibold mt-1 mb-1">Montant global  du {{dateFrom}} au {{dateTo}}: {{globalAmountIntervall}} CFA</span><br>
      <span class="font-weight-semibold mt-3 mb-3">Ratio des flux  du {{dateFrom}} au {{dateTo}} </span>
      <v-spacer></v-spacer>
      <ComptaPieChart :dataPieChartLabel="chartLabel" :dataPieChartData="paymentList" :fresh="hasFresh" @success-pie-update="hasFresh= false"></ComptaPieChart>
    </v-col>
  </v-row>
</div>
</template>

<script>
import ComptaPieChart from './ComptaPieChart'
import {json2excel, excel2json} from 'js2excel';
import Swal from 'sweetalert2'
import {VRow,VCol,VBtn, VDataTable,VSpacer,VToolbar, VTextField, VToolbarTitle} from 'vuetify'
export default {
  name: "ComptaFund",
  components: {
    VTextField,
    VToolbarTitle,
    VRow,
    VCol,
    VBtn,
    VDataTable,
    VSpacer,
    VToolbar,
    ComptaPieChart,
    },
  data() {
    return {
      id:0,
      search:"",
      fund_label:"",
      fund_amount:"",
      hasFresh:false,
      dateFrom: '',
      dateTo:'',
      paymentList: [],
      chartLabel:['Sortant', 'Entrant'],
    }
  },
  computed: {
    globalAmountIntervall(){
      if (this.paymentList.length > 0){
        return this.paymentList.map(item => item.pay_amount).reduce((prev,next)=> parseFloat(prev) + parseFloat(next),0)
      }
      return 0
    }
  },
  mounted() {
    let dtOne = new Date();
    let dtTwo = new Date();
    this.id = this.$route.params.id_fund;
    this.fund_amount = this.$route.params.amount;
    this.fund_label = this.$route.params.label;
    dtOne = new Date(dtOne.setMonth(dtOne.getMonth()-1, 7));
    this.dateFrom = dtOne.toISOString().split('T')[0];
    this.dateTo = dtTwo.toISOString().split('T')[0];
    console.log(this.dateFrom)
    this.getFundPaymentInterval();
  },
  setup(){
    return {
      headers: [
        { text: 'DATE', value: 'pay_date' },
        { text: 'MONTANT', value: 'pay_amount' },
        { text: 'LIBELLE DE LA TRANSACTION', value: 'pay_mode' },
        { text: 'TYPE DE PAIEMENT', value: 'type' },
      ],
    }
  },
  methods: {
    getFundPaymentInterval(){
      if (new Date(this.dateFrom) < new Date(this.dateTo)){
        const FunPayment = {
          fund_id: this.id,
          dateFrom: this.dateFrom,
          dateTo: this.dateTo,
        };
        this.$axios.post(this.$endpoint.LoadPaymentByFund, FunPayment).then(rp => {
          this.paymentList = rp.data
          this.hasFresh = true;
        }).catch(err => {
          console.log(err);
        });
      }else{
        // alert('error')
        this.showErrorAlert("L'intervalle de date choisi est incorrecte");
      }

    },
    showErrorAlert(msg= 'Hello Vue world!!!'){
      Swal.fire({
        icon: 'error',
        title: msg,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
      });

    },
    showSuccessAlert(msg= 'Hello Vue world!!!'){
      Swal.fire({
        icon: 'success',
        title: msg,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: toast => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
      });

    },
    exportToExcel(){
      try {
        const excelData=[];
        this.paymentList.map(item => {
          excelData.push({
            Date_de_transaction: item.pay_date,
            Montant: item.pay_amount,
            Libelle: item.pay_mode,
            Type_de_transaction:item.type
          })
        })
        json2excel({
          data:  excelData,
          name: this.dateFrom+':'+this.dateTo,
          formateDate: 'yyyy/mm/dd'
        });
      } catch (e) {
        console.error('export error: ', e);
      }
    }

  },
}
</script>

<style scoped>

</style>