<script>
import { Pie } from 'vue-chartjs'

export default {
  name: 'RoomPieChart',
  extends: Pie,
  // mixins:[VueChartJs.mixins.reactiveData],
  props:[
      'fresh',
      'dataPieChartData',
      'dataPieChartLabel',
  ],
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [{
          borderWidth: 1,
          borderColor: [
            'rgb(255,99,99)',
            'rgb(54, 162, 235, 1)',
          ],
          backgroundColor: [
            'rgb(255,99,99)',
            'rgba(54, 162, 235, 1)',
          ],
          // eslint-disable-next-line no-tabs
          data: [],
          hoverOffset: 4,

        }],
      },
      options: {
        legend: {
          display: true,
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      stayInvoice:[],
      roomType: [],
    }
  },
  mounted() {
    // setTimeout(()=> {
    //
    //   // alert(this.fresh)
    // }, 1000)
    this.loadData()
    this.chartData.labels = this.dataPieChartLabel
    this.renderChart(this.chartData, this.options)
  },
  watch:{
    fresh:function (){
      if (this.fresh===true){
        if (this.$data._chart){
          this.$data._chart.destroy();
        }
        this.loadData()
        this.chartData.labels = this.dataPieChartLabel
        this.renderChart(this.chartData, this.options)
        this.$emit('success-pie-update')
      }
    }
  },
  methods: {
    loadData() {
      let enter_flux, out_flux = []
      enter_flux = this.dataPieChartData.filter(am => am.type === "entrant")
      out_flux = this.dataPieChartData.filter(am =>  am.type === "sortant")
      this.chartData.datasets[0].data[0]= enter_flux.map(item => item.pay_amount).reduce((prev,next)=> parseFloat(prev) + parseFloat(next),0)
      this.chartData.datasets[0].data[1]= out_flux.map(item => item.pay_amount).reduce((prev,next)=> parseFloat(prev) + parseFloat(next),0)
    },
  },
}
</script>

<style scoped>

</style>
